import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChannels } from '../../redux/reducers/reducer';
import Loader from '../Loader';
import Header from './Header';
import Footer from './Footer';

class Welcome extends Component {
  constructor() {
    super();
    this.state = {
      channelsData: [],
    }
  }

  componentDidMount() {
    window.token = localStorage.getItem('token');
    if (!window.token) {
      this.props.history.push("/");
    }
    else if (localStorage.getItem('redirecto')) {
      this.props.history.push(localStorage.getItem('redirecto'));
      localStorage.removeItem('redirecto')
    }

    let serviceProvider = 'awsInteractiveVideo'
    this.props.getChannels({ type: serviceProvider });

  }

  componentDidUpdate(prevProps) {

    if (prevProps.channels !== this.props.channels) {
      let channelsData = (this.props.channels || []);
      this.setState({ channelsData })
    }
  }




  logOut(e) {
    if (window.site && window.site.config.authentication.key === "auth0") {
      if (window.signinLock) {
        window.signinLock.logout()
        console.log('logged out');
      }
    }
    e.stopPropagation();
    e.preventDefault();
    let appStorage = window.app.storage;
    let appName = appStorage.getItem('appName');
    let appEnvName = appStorage.getItem('appEnvName');
    let windowLocation = appStorage.getItem('windowLocation');
    appStorage.clear();
    if (appName) appStorage.setItem('appName', appName);
    if (appEnvName) appStorage.setItem('appEnvName', appEnvName);
    if (windowLocation) appStorage.setItem('windowLocation', windowLocation);
    window.location = '/';
  };


  broadcastClick(channel) {
    let self = this;
    window.location = `/broadcast?channelCode=${channel.channelcode}`
  }

  playChannelAssets(channel) {
    let self = this;
    //window.location = `/broadcast?channelCode=${channel.channelcode}`
  }



  render() {
    let self = this;
    const { channelsData } = self.state;
    let siteSettings = window.site;
    let siteConfig = siteSettings.config;
    let imagesPath = siteConfig.imagesAssetResourcesPrefix;

    let wiw_profileImage = localStorage.getItem("userImage");

    if (wiw_profileImage === null || wiw_profileImage === "" || wiw_profileImage === 'undefined') {
      wiw_profileImage = imagesPath + siteSettings.themePrefix + "profiles/default/avatar1.jpg"
    }


    return (
      <React.Fragment>
        <div>
          <Header />

          <div className="envlive_wrapper minbody-ht">
            <div className="container-fluid pt-5 ">
              <div className="page-heading">
                <div className="block-group">
                  <h2>My channel</h2>
                  <button className="grad-btn"><span className="material-symbols-outlined"> add </span>Create</button>
                </div>
              </div>
              {this.props.loading && <Loader />}
              <div className="row">
                {!this.props.loading && channelsData && channelsData.length > 0 && channelsData.map((channel, i) => {
                  return (
                    <div className="col-md-3" key={i}>
                      <div className="channel-card">
                        <div className="channel-thumb" >
                          <img alt="channel" src={channel.landscape_logo_image ? channel.landscape_logo_image : siteConfig.defaultLandscape} />
                          {channel.serviceprovider == 'awsInteractiveVideo' ? <a href="#!" onClick={() => self.broadcastClick(channel)} className="broadcast_play" title="Broadcast"><span className="material-icons">podcasts</span></a>
                            : <a href="#!" className="asset_play" title="Play" onClick={() => self.playChannelAssets()}><span className="material-icons">play_arrow</span></a>}
                          <div className="thumb-footer">
                            <div>
                              <div className="dropdown actions-dd">
                                <a className="roundbtns" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"><span className="material-symbols-outlined">
                                  more_horiz
                                </span></a>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li><a className="dropdown-item" href="#"><span className="material-symbols-outlined"> edit </span>Edit</a></li>
                                  <li><a className="dropdown-item" href="#"><span className="material-symbols-outlined"> share </span>Share</a></li>
                                </ul>
                              </div>
                            </div>

                            <a className="publish-btn selected"><span className="material-symbols-outlined"> cloud_upload </span><span className="pubtxt"></span></a>
                          </div>
                        </div>
                        <div className="channel-footer">
                          <h5>{channel.channelname}</h5>

                        </div>
                      </div>
                    </div>)
                })}



              </div>

            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )

  }
}

const mapState = ({ channels, loading }) => ({ channels, loading });
const mapDispatch = { getChannels };
export default connect(mapState, mapDispatch)(Welcome);