import React from 'react';
const Loader = () => {
    return (
        <div id="overlay">
            <div className="preLoader">
                <div className="loftloader-wrapper pl-frame">
                    <div className="loader">
                        <div className="spark"/>
                        <img src={window.site.preLoader} alt=""/>
                        <span/>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default (Loader);
