import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAssets } from '../../redux/reducers/reducer';
import Header from './Header';
import Footer from './Footer';


class Recordings extends Component {
    constructor() {
        super();
        this.state = {
            assetCount: 16,
            pageNumber: 1
        }
    }





    componentDidMount() {
        const { assetCount, pageNumber } = this.state;
        let payload = { assetCount, pageNumber }
        this.props.getAssets(payload);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.assets != this.props.assets) {
            console.log("assets", this.props.assets)
        }
    }



    render() {
        let self = this;
        return (
            <React.Fragment>
                <div className="recordings_block">
                    <Header />
                    <h1>Coming soon...</h1>
                    <Footer />
                </div>
            </React.Fragment>
        )

    }
}
const mapState = ({ assets, loading }) => ({ assets, loading });
const mapDispatch = { getAssets };
export default withRouter(connect(mapState, mapDispatch)(Recordings));