import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { } from '../../redux/reducers/reducer';
import Header from './Header';
import Footer from './Footer';


class Playlists extends Component {
  constructor() {
    super();
    this.state = {

    }
  }





  componentDidMount() {


  }

  componentDidUpdate(prevProps) {

  }



  render() {
    let self = this;
    return (
      <React.Fragment>
        <div className="playlists_block">
          <Header />
          <h1>Coming soon...</h1>
          <Footer />
        </div>
      </React.Fragment>
    )

  }
}
const mapState = ({ loading }) => ({ loading });
const mapDispatch = {};
export default withRouter(connect(mapState, mapDispatch)(Playlists));