import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChannels } from '../../redux/reducers/reducer';


// Set initial config for our broadcast
const config = {
    ingestEndpoint: "68cbf334f909.global-contribute.live-video.net",
    streamConfig: window.IVSBroadcastClient.BASIC_LANDSCAPE,
    logLevel: window.IVSBroadcastClient.LOG_LEVEL.DEBUG
};

const location = (name) => {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
        window.location.href
    );
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
};


const channelCode = location("channelCode");
class Broadcast extends Component {
    constructor() {
        super();
        this.state = {
            channelCode,
            isChannelStart: false,
            isAudioStart: true,
            isCameraEnabled: true,
        }
    }





    componentDidMount() {
        let payload = { channelCode: this.state.channelCode }
        let appStorage = window.app.storage;
        window.token = appStorage.getItem('token');
        if (window.token) this.props.getChannels(payload);

    }

    componentDidUpdate(prevProps) {

        if (prevProps.channels !== this.props.channels) {
            let channelsData = ((this.props.channels || [])[0]) || {};
            this.setState({ channelsData }, () => {
                this.init();
            })

        }
    }

    // Initialization 
    async init() {
        try {
            await this.createClient();
        } catch (err) {
            this.setError(err.message);
        }
    }


    setError(message) {
        if (Array.isArray(message)) {
            message = message.join("<br/>");
        }
        const errorEl = document.getElementById("error");
        console.log("errorEl", errorEl)
        errorEl.innerHTML = message;
    }



    async getCamera(deviceId, maxWidth, maxHeight) {
        let media;
        const videoConstraints = {
            deviceId: deviceId ? { exact: deviceId } : null,
            width: {
                max: maxWidth
            },
            height: {
                max: maxHeight
            }
        };
        try {
            // Let's try with max width and height constraints
            media = await navigator.mediaDevices.getUserMedia({
                video: videoConstraints,
                audio: true
            });
        } catch (e) {
            // and fallback to unconstrained result
            delete videoConstraints.width;
            delete videoConstraints.height;
            media = await navigator.mediaDevices.getUserMedia({
                video: videoConstraints
            });
        }
        return media;
    }



    // Handle video device retrieval
    async handleVideoDeviceSelect() {
        const id = "camera";
        // const videoSelectEl = document.getElementById("video-devices");

        const { videoDevices: devices } = await this.getDevices();
        console.log('getVideoInputDevice', window.client.getVideoInputDevice(id))
        if (window.client.getVideoInputDevice(id)) {
            window.client.removeVideoInputDevice(id);
        }

        // Get the option's video
        // const selectedDevice = devices.find(
        //   (device) => device.deviceId === videoSelectEl.value
        // );
        // const deviceId = selectedDevice ? selectedDevice.deviceId : null;

        const deviceId = (devices && devices.length > 0 && devices[0].deviceId) || null;
        const { width, height } = config.streamConfig.maxResolution;
        const cameraStream = await this.getCamera(deviceId, width, height);

        // Add the camera to the top
        await window.client.addVideoInputDevice(cameraStream, id, {
            index: 0
        });
        this.setState({ isCameraEnabled: false })
    }

    // Handle audio/video device enumeration
    async getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((d) => d.kind === "videoinput");
        if (!videoDevices.length) {
            this.setError("No video devices found.");
        }
        const audioDevices = devices.filter((d) => d.kind === "audioinput");
        if (!audioDevices.length) {
            this.setError("No audio devices found.");
        }

        return { videoDevices, audioDevices };
    }

    // Handle audio device retrieval
    async handleAudioDeviceSelect() {
        const id = "microphone";
        // const audioSelectEl = document.getElementById("audio-devices");
        const { audioDevices: devices } = await this.getDevices();
        if (window.client.getAudioInputDevice(id)) {
            window.client.removeAudioInputDevice(id);
        }
        // if (audioSelectEl.value.toLowerCase() === "none") return;
        // const selectedDevice = devices.find(
        //   (device) => device.deviceId === audioSelectEl.value
        // );
        // Unlike video, for audio we default to "None" instead of the first device
        const deviceId = (devices && devices.length > 0 && devices[0].deviceId) || null;
        if (deviceId) {
            const microphoneStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: deviceId
                }
            });
            await window.client.addAudioInputDevice(microphoneStream, id);
            this.setState({ isAudioStart: false })
        }
    }


    checkChannelStatus() {
        window.client.on(
            window.IVSBroadcastClient.BroadcastClientEvents.ACTIVE_STATE_CHANGE,
            (active) => {
                this.onActiveStateChange(active);
            }
        );
    }



    // Start the broadcast
    async startBroadcast() {
        const endpointEl = (((this.state.channelsData || {}).awsIntractiveVideoService || {}).channel || {}).ingestEndpoint;
        const streamKeyEl = ((this.state.channelsData || {}).streamConfigurationInfo || {}).streamKey;
        console.log("streamKeyEl", streamKeyEl)
        console.log("endpointEl", endpointEl)
        try {
            await window.client.startBroadcast(streamKeyEl, endpointEl);
            this.checkChannelStatus();
        } catch (err) {
            this.setError(err.toString());
        }
    }

    // Stop the broadcast
    async stopBroadcast() {
        try {
            await window.client.stopBroadcast();
            this.checkChannelStatus();
        } catch (err) {
            this.setError(err.toString());
        }
    }

    // Handle the enabling/disabling of buttons
    onActiveStateChange(active) {
        this.setState({ isChannelStart: active })
    }

    // Helper to create an instance of the AmazonIVSBroadcastClient
    async createClient() {
        if (window.client) {
            window.client.delete();
        }
        window.client = window.IVSBroadcastClient.create(config);
        this.checkChannelStatus();
        const previewEl = document.getElementById("preview");
        window.client.attachPreview(previewEl);
        await this.handleVideoDeviceSelect();
        await this.handleAudioDeviceSelect();
    }

    backToChannels() {
        window.location = `/home`
    }

    muteAudio() {
        const id = "microphone";
        let audioStream = window.client.getAudioInputDevice(id);
        console.log("audioStream", audioStream)
        audioStream.getAudioTracks()[0].enabled = false;
        this.setState({ isAudioStart: true })
    }

    async hideVideo() {
        const id = "camera";
        // let videoStream = window.client.getVideoInputDevice(id);
        // console.log("videoStream", videoStream)
        // videoStream.getVideoTracks()[0].enabled = false;
        // await window.client.disableVideo()
        await window.client.removeVideoInputDevice(id);
        this.setState({ isCameraEnabled: true })
    }

    render() {
        let self = this;
        return (
            <React.Fragment>
                <div className="streaming_block">


                    {/* <!-- Error alert --> */}
                    <div className="streaming_error">
                        <span id="error"></span>
                    </div>
                    <div className="mobile-controls">
                        <div className="top_options">
                            <div className="views"> <span className="material-icons">visibility</span> 2090 </div>
                            {this.state.isChannelStart ? <div className="live"> <span className="dot"></span>LIVE</div>
                                : <div className="live"> </div>}
                            <div className="stream_settings">
                                <a href="#" className="icon"><span className="material-icons">fullscreen</span> </a>
                                <a href="#" className="icon"><span className="material-icons">settings</span> </a>
                                <button className="streaming_close" onClick={() => this.backToChannels()}> <span className="material-icons">close</span></button>
                            </div>
                        </div>

                    </div>
                    <div className="live_streaming">
                        <button className="streaming_close" onClick={() => this.backToChannels()}> <span className="material-icons">close</span></button>
                        <div className="top_options">
                            {this.state.isChannelStart ? <div className="live"> <span className="dot"></span>LIVE</div>
                                : <div className="live"> </div>}
                            <div className="views"> <span className="material-icons">visibility</span> 2090 </div>
                        </div>
                        {/* <!-- Compositor preview --> */}
                        <canvas id="preview"></canvas>
                        {/* <!-- Broadcast buttons --> */}
                        <div className="streaming_buttons">
                            <div className="stream_duration"> </div>
                            <div className="stream_options">
                                {this.state.isAudioStart && <button className="sub_button mic_off" onClick={() => this.handleAudioDeviceSelect()}> <span className="material-icons">mic_off</span> </button>}
                                {!this.state.isAudioStart && <button className="sub_button" onClick={() => this.muteAudio()}> <span className="material-icons">mic</span> </button>}
                                {this.state.isCameraEnabled && <button className="sub_button came_off" onClick={() => this.handleVideoDeviceSelect()}> <span className="material-icons">videocam_off</span> </button>}
                                {!this.state.isCameraEnabled && <button className="sub_button" onClick={() => this.hideVideo()}> <span className="material-icons">videocam</span> </button>}
                                {!this.state.isChannelStart && <button className="start_button" onClick={() => this.startBroadcast()}> <span className="material-icons">fiber_manual_record</span> </button>}
                                {this.state.isChannelStart && <button className="stop_button" onClick={() => this.stopBroadcast()}> <span className="material-icons">stop</span> </button>}
                                <button className="sub_button"> <span className="material-icons">screen_share</span> </button>
                                <button className="sub_button"> <span className="material-icons">question_answer</span> </button>
                            </div>
                            <div className="stream_settings">
                                <a href="#" className="icon"><span className="material-icons">fullscreen</span> </a>
                                <a href="#" className="icon"><span className="material-icons">settings</span> </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="chat_block"></div> */}

                </div>
            </React.Fragment>
        )

    }
}
const mapState = ({ channels, loading }) => ({ channels, loading });
const mapDispatch = { getChannels };
export default withRouter(connect(mapState, mapDispatch)(Broadcast));