export const ACTIVATION_CODE = "ACTIVATION_CODE";
export const ADD_PROFILE = "ADD_PROFILE";
export const APPROVED_ASSET = "APPROVED_ASSET";
export const ASSET_PAYMENT = "ASSET_PAYMENT";
export const BRAINTREE_CUSTOMER = "BRAINTREE_CUSTOMER";
export const GENERATE_ACTIVATION_CODE = "GENERATE_ACTIVATION_CODE";
export const GENERATE_USER_RELATION = "GENERATE_USER_RELATION";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ALL_PARTNERS = "GET_ALL_PARTNERS";
export const GET_ASSET_INFO = "GET_ASSET_INFO";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_EVENT_INFO = "GET_EVENT_INFO";
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_REGIONS = "GET_REGIONS";
export const GET_USER = "GET_USER";
export const GET_WEBSITE_DEFAULT_DATA = "GET_WEBSITE_DEFAULT_DATA";
export const ITEMS_IS_LOADING = "ITEMS_IS_LOADING";
export const GET_CLIPS = "GET_CLIPS";
export const CREATE_CLIPS = "CREATE_CLIPS";
export const GIVE_VOTE = "GIVE_VOTE";
export const GET_LOCATION = 'GET_LOCATION';

export const GET_ASSETS= 'GET_ASSETS';
