import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
let menus = [{
    menu: 'Channels',
    menuId: 'Channels',
    route: '/home'
},
{
    menu: 'Recordings',
    menuId: 'Recordings',
    route: '/recordings'
},
{
    menu: 'Playlists',
    menuId: 'Playlists',
    route: '/playlists'
}]
class Header extends Component {
    constructor() {
        super();
        this.state = {
            channelsData: [],
            activeMenu: window.location.pathname,
        }
        this.handleMenu = this.handleMenu.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (prevProps.channels !== this.props.channels) {
            let channelsData = (this.props.channels || []);
            this.setState({ channelsData })
        }
    }




    logOut(e) {
        if (window.site && window.site.config.authentication.key === "auth0") {
            if (window.signinLock) {
                window.signinLock.logout()
                console.log('logged out');
            }
        }
        e.stopPropagation();
        e.preventDefault();
        let appStorage = window.app.storage;
        let appName = appStorage.getItem('appName');
        let appEnvName = appStorage.getItem('appEnvName');
        let windowLocation = appStorage.getItem('windowLocation');
        appStorage.clear();
        if (appName) appStorage.setItem('appName', appName);
        if (appEnvName) appStorage.setItem('appEnvName', appEnvName);
        if (windowLocation) appStorage.setItem('windowLocation', windowLocation);
        window.location = '/?appname=' + appName;
    };


    handleMenu = (item) => {
        this.setState({ activeMenu: item.route })
        browserHistory.push(item.route);
    }





    render() {
        let self = this;
        const { } = self.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        return (
            <React.Fragment>
                <header className="fixed-top live-header">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md live-nav ">
                            <div className="nav-brand live-brand">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="material-symbols-outlined"> menu </span>
                                </button>
                                <a className="navbar-brand" href="#">
                                    <img src={siteConfig.envoiBroadcast.landingPage.logo} />
                                </a>
                            </div>
                            <div className="collapse navbar-collapse live-menu" id="navbarNav">
                                <ul className="navbar-nav live-navlist">
                                    {menus && menus.length > 0 && menus.map((menu, i) => {
                                        let menuClass = (self.state.activeMenu == menu.route) ? "nav-link active" : "nav-link"
                                        return (
                                            <li className="nav-item" key={i}>
                                                <a href="" className={menuClass} aria-current="page" onClick={() => this.handleMenu(menu)}>{menu.menu}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>

                            <div className="block-group profile-block">
                                <a className="roundbtns me-3"><span className="material-symbols-outlined">
                                    search
                                </span></a>
                                <div className="dropdown profile-dd">
                                    <button className="profile-btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {/* <img src={siteConfig.envoiBroadcast.landingPage.logo} /> */}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#"><span className="material-symbols-outlined"> person_filled </span></a></li>
                                        <li><a className="dropdown-item" href="#"><span className="material-symbols-outlined"> settings </span>Settings</a></li>
                                        <li><a className="dropdown-item" href="#!" onClick={(e) => self.logOut(e)}><span className="material-symbols-outlined"> trending_up </span>Log Out</a></li>
                                    </ul>
                                </div>
                            </div>

                        </nav>
                    </div>
                </header>

            </React.Fragment>
        )

    }
}

const mapState = ({ loading }) => ({ loading });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Header);