import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import NavLink from '../NavLink';
import {  activationCode, generateActivationCode } from '../../redux/reducers/reducer';

class AddDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            continueBtn: false
        }
        this.formValidation = this.formValidation.bind(this);
        this.change = this.change.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
       
    }

    componentDidMount() {
        // let self = this;
        // let deviceName = "Apple iPhone";
        // self.props.generateActivationCode(deviceName);
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.generateActivationCodeProps !== this.props.generateActivationCodeProps) {
            self.setState({ continueBtn: false })
            browserHistory.push("/activation");
        }
    }


    formValidation() {
        let self = this;
        let errors = { ...self.state.errors };
        let formIsValid = true;
        if (self.state.device && self.state.device !== "undefined") {
            formIsValid = false;
            errors.device = "Please select device";
        }
        self.setState({ errors: errors })
        return formIsValid
    }
    change(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            this.setState({
                errors
            });
        }

    }
    handleContinue() {
        let self = this;
        if (self.formValidation()) {
            self.setState({ continueBtn: true })
            self.props.generateActivationCode(self.state.device);
            localStorage.setItem("addDevice", self.state.device);
        }
    }

    render() {
        let self = this;
        let style = {
            color: "red"
        }
        let logoImage = window.site.config.imagePrefix + "screening/images/headerlogo.png";
        const { continueBtn, errors} = this.state;
        return (
            <div className="act_page">
                <div className="activate_body" >
                    <div className="active_wrp">
                        <div className="active_logo"><NavLink to="/"><img src={logoImage} alt="" /></NavLink></div>
                        <div className="active_block">
                            <h2>ACTIVATE DEVICE</h2>
                            <p>Select your mobile device</p>
                            <select className="" name="device" onChange={e => this.change(e)} value={this.state.device}>
                                <option value="">My mobile is a:</option>
                                <option value="Android Mobile">Android Mobile</option>
                                <option value="Apple iPhone">Apple iPhone</option>
                            </select>
                            <p className="errmsg" style={style}>{errors.device}</p>
                            <div className="form-group">
                                <button onClick={e => self.handleContinue()} type="button" className="cncl marg-right0">{continueBtn ? "Sending..." : "CONTINUE"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapState = ({ activationCodeProps, generateActivationCodeProps }) => ({ activationCodeProps, generateActivationCodeProps });
const mapDispatch = { activationCode, generateActivationCode };
export default connect(mapState, mapDispatch)(AddDevice);
