import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavLink from '../NavLink';
import { getArtists, getUser, customerAddress, updateUserRelation, addProfiles, getCountries, getRegionsAPI, getAddress, generateActivationCode, addCustomerInBrainTree, getChannels } from '../../redux/reducers/reducer';
import { isValidNumber } from 'libphonenumber-js';
import $ from 'jquery';
import _ from 'lodash';

var userinfo = {};
var customer_address = {};

var addressVisible = localStorage.getItem("adv");
let token = localStorage.getItem('token');


class CreateAnAccount extends Component {
    constructor(props) {
        super(props);


        var adr = JSON.parse(localStorage.getItem("adr"));
        var partnerhead = localStorage.getItem("partnerid");
        var partnername = "";
        if (partnerhead) {
            localStorage.setItem("partnerid", partnerhead);
        } else {
            userinfo["partnername"] = "";
        }
        var isLoggedIn = false;

        var firstname = localStorage.getItem("firstname") || ''
        var lastname = localStorage.getItem("lastname") || ''
        var email = localStorage.getItem("email") || ''
        const streamObj = {
            streamUrl: '',
            password: '',
            enable: false
        }
        this.state = {
            data: [],
            userinfo,
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnerhead,
            partnerid: partnerhead,
            partnername: partnername,
            partnerinfo: null,
            selectpartner: '',
            errors: {},
            value: '',
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: "",
            modifiedavatar: window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + 'profiles/default/avatar1.jpg' + window.site.config.imageSuffix,
            countrydefault: "",
            regiondefault: "",
            customer_address: {},
            adr: adr,
            addressVisible: addressVisible,
            countryCode: window.countryCode || "+1 (###) ###-####",
            eventsApp: false,
            isProfile: true,
            isAddress: false,
            isSocialLinks: false,
            destinations: {
                youtube: streamObj,
                facebook: streamObj,
                instagram: streamObj,
                twitch: streamObj,
                linkedin: streamObj,
            }

        };
        this.updateRelation = this.updateRelation.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
        token = localStorage.getItem('token');
        if (!token) {
            self.props.history.push("/");
        }
        this.props.getCountries();

        this.props.getUser(token);
        if (self.state.isAddress) {
            this.props.getAddress("custom");
        }
        if (self.state.adr && self.state.adr.extendedAddress) {
            self.props.getRegionsAPI(self.state.adr.countryCodeAlpha2)
        } else {
            self.props.getRegionsAPI(self.state.countrydefault)
        }
        userinfo["partnername"] = "";
        customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        customer_address["region"] = self.state.regiondefault;
        customer_address["addresstype"] = "custom";
        this.setState({ customer_address, selectpartner: "", partnerinfo: {} });
        var partinterval = setInterval(function () {
            if (self.props.partners && self.props.partners.length > 0) {
                _.filter(self.props.partners, function (user) {
                    if (self.state.partnerid === user.partnerid) {
                        self.setState({
                            partnername: user.name,
                            partnercode: user.partnerid,
                            selectpartner: user.name + "_" + user.partnerid
                        })
                        clearInterval(partinterval);
                    }
                });
            }
        }, 1000);
        var interval = setInterval(function () {
            if (self.props.userinfo && self.props.userinfo.firstname) {
                self.setState({
                    phone: self.props.userinfo.phone,
                    firstname: self.props.userinfo.firstname,
                    lastname: self.props.userinfo.lastname,
                    email: self.props.userinfo.emailid,
                    idc: self.props.userinfo.idc,
                })
                clearInterval(interval);
            }
        }, 1000);
        setTimeout(function () {
            if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                self.props.getRegionsAPI(self.props.address[0].countryCodeAlpha2)
                delete self.props.address[0].user_id;
                delete self.props.address[0]._id
                self.setState({
                    adr: self.props.address[0]
                })
            }
        }, 5000);

        let userType = localStorage.getItem("userType");
        if (userType != null) {
            userType = JSON.parse(userType);
            if (userType.param === "event") {
                self.setState({ eventsApp: true })
            }
        }

        let hostName = window.location.hostname;
        hostName = hostName.split(".");
        if (Array.isArray(hostName)) {
            hostName = hostName[0].split("-");
        }
        let hostNameIsArray = Array.isArray(hostName);
        if (hostNameIsArray === true) {
            if (hostName[0] === "events")
                self.setState({ eventsApp: true })
        }
    }
    componentDidUpdate(prevProps) {
        var self = this;
        if (prevProps.userRelationData !== this.props.userRelationData) {
            console.log("type...did update", self.state.type)
            switch (self.state.type) {
                case 'isProfile':
                    self.setState({ isProfile: false, isAddress: true, isSocialLinks: false, });
                    break;
                case 'isAddress':
                    self.setState({ isProfile: false, isAddress: false, isSocialLinks: true, });
                    break;
                case 'isSocialLinks':
                    self.setState({ isProfile: false, isAddress: false, isSocialLinks: false, });
                    // this.props.history.push("/home");
                    let serviceProvider = 'awsInteractiveVideo'
                    self.props.getChannels({ type: serviceProvider });
                    break;
            }
        }
        if (prevProps.countries !== this.props.countries) {
            if (localStorage.getItem("loc")) {
                let locationObj = JSON.parse(localStorage.getItem("loc"));
                let locationData = locationObj && locationObj.headers || {};
                let countryName = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value;
                let countryObj = this.props.countries.find(country => country.countryname === countryName);
                this.setState({ idc: countryObj.idc })
            }
        }

        if (prevProps.channels !== this.props.channels) {
            let channelsData = (this.props.channels || []);
            this.setState({ channelsData })
            if (channelsData && channelsData.length > 0) {
                let userType = localStorage.getItem('usertype');
                if (userType == 'creator') {
                    if(channelsData[0].serviceprovider=='awsInteractiveVideo'){
                        window.location = `/broadcast?channelCode=${channelsData[0].channelcode}`
                    }else{
                        this.setState({ showChannelsNotFound: true })
                    }
               
                } else {
                    window.location = `/home`
                }
            } else {
                window.location = `/home`
            }

        }
    }
    formValidation() {
        let errors = {};
        let formIsValid = true;
        const eml = this.validate_onchange_email();
        // if ((this.state.partnerinfo == undefined || this.state.partnerinfo == null)) {
        //     formIsValid = false;
        //     errors.name = "Please select influencer";
        // }
        if (this.state.firstname === undefined || this.state.firstname === "") {
            formIsValid = false;
            errors.firstname = "Firstname Required";
        }
        if (this.state.lastname === undefined || this.state.lastname === "") {
            formIsValid = false;
            errors.lastname = "Lastname Required";
        }
        if (this.state.email === undefined || (this.state.email === "" && !eml)) {
            formIsValid = false;
            errors.email = "Email Required";
        }
        if ((this.state.phone === undefined || this.state.phone === '' || this.state.phone === 'false') && !isValidNumber(this.state.phone)) {
            formIsValid = false;
            errors.phone = "Invalid Phone Number";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    updateRelation(e, type) {
        e.stopPropagation();
        e.preventDefault();
        var self = this;
        token = localStorage.getItem('token');
        if (self.formValidation() && token) {
            $("#overlay").removeClass("hidediv");
            var userdata = self.state.userinfo || {};
            userdata["firstname"] = this.state.firstname;
            userdata["lastname"] = this.state.lastname;
            userdata["emailid"] = this.state.email;
            userdata["accountType"] = window.accountType || "customer";
            userdata['customer_address'] = this.state.customer_address || {};
            if (window.paymentEnable) {
                userdata["status"] = "PENDING SUBSCRIPTION";
            }
            else if (window.requiredVerification) {
                userdata["status"] = "PENDING VERFICATION";
            }
            let hostName = window.location.hostname;
            hostName = hostName.split(".");
            if (Array.isArray(hostName)) {
                hostName = hostName[0].split("-");
            }
            let hostNameIsArray = Array.isArray(hostName);
            if (hostNameIsArray) {
                switch (hostName[0]) {
                    case "creators":
                        userdata["usertype"] = "creator";
                        break;
                    case "partners":
                        userdata["usertype"] = "partner";
                        break;
                    default:
                        break;
                }
            }
            userdata["phone"] = this.state.phone || 'false';
            userdata["idc"] = this.state.idc;
            // for skipping verification otp process
            if (!window.requiredVerification) {
                userdata["isVerified"] = true;
            }
            // userdata["partnercode"] = this.state.partnercode;/
            // userdata["partnername"] = this.state.partnername;
            localStorage.setItem("firstname", this.state.firstname);
            localStorage.setItem("lastname", this.state.lastname);
            self.setState({ type: type })
            self.props.updateUserRelation(userdata);
            // if (requiredSelectDevice == false) {
            //     self.props.generateActivationCode('Android Mobile');
            // }
            // if (paymentEnable == false) {
            //     self.props.addCustomerInBrainTree();
            // }
            // if (self.state.addressVisible === "true") {
            if (self.state.isAddress && this.state.customer_address && this.state.customer_address.extendedAddress) {
                if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                    self.props.customerAddress(this.state.customer_address, self.props.address[0]._id)
                    localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                } else {
                    self.props.customerAddress(this.state.customer_address, "new")
                    localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                }
            } else {
                if (self.state.isAddress && this.state.adr && this.state.adr.extendedAddress) {
                    if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                        self.props.customerAddress(this.state.adr, self.props.address[0]._id)
                        localStorage.setItem("adr", JSON.stringify(this.state.adr));
                    } else {
                        self.props.customerAddress(this.state.adr, "new")
                        localStorage.setItem("adr", JSON.stringify(this.state.adr));
                    }
                }
            }
            // }
            // if (self.state.firstname) {
            //     self.props.addProfiles(self.state.firstname, self.state.modifiedavatar);
            // }
            localStorage.setItem("profileName", self.state.firstname);
            localStorage.setItem("profileImage", self.state.modifiedavatar);
            localStorage.setItem("phone", this.state.phone);
            localStorage.setItem("transactionalmessages", this.state.phone);
        }
    }
    validate_onchange_email() {
        let formIsValid = false;
        let errors = {};
        var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.email) === false) {
            formIsValid = true;
            errors.email = "Invalid Email ID";
        }
        this.setState({ errors });
        return formIsValid;
    }
    addressChange(e) {
        var self = this;
        const name = e.target.name;
        var value = e.target.value;
        if (name === "countryCodeAlpha2") {
            self.props.getRegionsAPI(value)
        }
        this.setState({ [name]: value });
        customer_address[name] = value;
        this.setState({ customer_address })
    }
    onChange(e) {
        let self = this;
        const name = e.target.name;
        var value = e.target.value;
        if (e.target.type == 'checkbox') {
            value = e.target.checked;
        }
        let userinfo = self.state.userinfo;
        let errors = Object.assign({}, this.state.errors);
        if (e.target.name === "phone") {
            let validNumber = this.validatePhoneNumber('+' + this.state.idc + ' ' + value);
            if (!validNumber) {
                delete errors[name];
                userinfo[name] = value;
                errors[name] = "Invalid Phone Number"
                this.setState({
                    [name]: value,
                    errors,
                    userinfo
                })
            } else {
                delete errors[name];
                userinfo[name] = value;
                this.setState({ userinfo, errors, [name]: value })
            }
        } else if (!!this.state.errors[name]) {
            delete errors[name];
            userinfo[name] = value;
            this.setState({
                [name]: value,
                errors,
                userinfo
            });
        } else {
            userinfo[name] = value;
            this.setState({ userinfo, [name]: value })
        }
    }

    logOut(e) {
        e.stopPropagation();
        e.preventDefault();
        var myAppName = localStorage.getItem('appName');
        localStorage.clear();
        localStorage.setItem('appName', myAppName);
        window.location = '/';
    }

    onSelect2(e) {
        let userinfo = self.state.userinfo;
        let code = e.target.value;
        userinfo['idc'] = code;
        this.setState({ idc: code, userinfo });
        if (this.state.phone) {
            this.validatePhoneNumber('+' + code + ' ' + this.state.phone);
        }
    }

    validatePhoneNumber(phoneNumber) {
        /*
        Phone number validation using google-libphonenumber
        */
        let errors = Object.assign({}, this.state.errors);
        let valid = false;
        try {
            const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
        } catch (e) {
            // console.log('error here', e);
            valid = false;
        }
        if (valid) {
            this.setState({
                message: ''
            });
        } else {
            errors['phone'] = 'Phone number ' + phoneNumber + ' is not valid';
            this.setState({
                errors
            });
        }
        return valid;
    }

    onChangeSocial = (e, type) => {
        let self = this;
        const name = e.target.name;
        var value = e.target.value;
        let userinfo = self.state.userinfo;
        if (e.target.type == 'checkbox') {
            value = e.target.checked;
        }
        let errors = Object.assign({}, this.state.errors);
        let destinations = { ...self.state.destinations };
        let obj = { ...destinations[type] };
        console.log("obj", obj)
        obj[name] = value;
        destinations[type] = { ...obj };
        if (!!this.state.errors[name]) {
            delete errors[name];
            userinfo['destinations'] = destinations;
            this.setState({
                [type]: obj,
                destinations,
                errors,
                userinfo
            });
        } else {
            userinfo['destinations'] = destinations;
            this.setState({ userinfo, [type]: obj, destinations })
        }
    }
    render() {
        let self = this;
        let payment_bg = window.site.config.imagesAssetResourcesPrefix + "screening/images/landscape_background.jpg";
        if (this.props.windowWidth < 801) {
            payment_bg = window.site.config.imagesAssetResourcesPrefix + "screening/images/landBg_portrait1.jpg";
        }
        const { isAddress, isProfile, isSocialLinks, destinations } = this.state;
        return (
            <div className="create_section">

                {this.props.loading && <div id="overlay" >
                    <div className="preloader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="preloader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="logo" src={window.site.logoImage} /></div>
                        <button onClick={(e) => this.logOut(e)} className="cls" />
                    </div>
                </div>

                <div className="bc_contant">
                    <h2>LET’S GET STARTED!</h2>
                    <div className="bc_form_wrp">
                        {isProfile &&
                            <div className="form_box">
                                <h3>Step 1: Create Your Profile</h3>
                                <div className="form_fields">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="capitalize" value={this.state.firstname} placeholder="First name" name="firstname" onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.firstname}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="capitalize" value={this.state.lastname} placeholder="Last name" name="lastname" onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.lastname}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={this.state.email} name="email" placeholder="Email" onBlur={e => this.validate_onchange_email(e)} onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <select className="phone_Country" value={this.state.idc} placeholder="Country" name="idc" onChange={e => this.onSelect2(e)}>
                                                <option >Country</option>
                                                {this.props.countries && this.props.countries.length > 0 && this.props.countries.map(function (country, i) {
                                                    return (
                                                        <option key={i} value={country.idc}>{country.countryname + ' (' + country.idc + ')'}</option>
                                                    )
                                                })}
                                            </select>
                                            <span className="errormsg">{this.state.errors.country}</span>
                                        </div>
                                        <div className="form-group col-md-9">
                                            <input type="text" className="field" disabled={!this.state.idc} placeholder="Enter Valid Phone Number" value={this.state.phone} name="phone" onChange={e => this.onChange(e)} />
                                            <span className="errormsg" >{this.state.errors.phone}</span>
                                            <div className="message" style={{ color: this.state.color }}>
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <p className="terms"><NavLink href="/terms" target="_blank">  Terms of Service.</NavLink></p>
                                        <div className="form-group align-center">
                                            <a href="#!" onClick={e => this.updateRelation(e, 'isProfile')} className="btn">Next</a>
                                        </div>
                                    </>

                                </div>

                            </div>

                        }
                        {isAddress && <div className="form_box">
                            <h3>Step 2: Add Some Info</h3>
                            <div className="form_fields">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" value={this.state.streetAddress ? this.state.streetAddress : this.state.adr ? this.state.adr.streetAddress : ''} placeholder="Street address, P.O. box, Company name, C/o" name="streetAddress" onChange={e => this.addressChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" value={this.state.extendedAddress ? this.state.extendedAddress : this.state.adr ? this.state.adr.extendedAddress : ''} placeholder="Apartment, suite, unit, building, floor etc" name="extendedAddress" onChange={e => this.addressChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select value={this.state.countryCodeAlpha2 ? this.state.countryCodeAlpha2 : this.state.adr ? this.state.adr.countryCodeAlpha2 : this.state.countrydefault} name="countryCodeAlpha2" className="capitalize" onChange={e => this.addressChange(e)}>
                                                <option value="">Country</option>
                                                {this.props.countries && this.props.countries.map((task, i) => {
                                                    return (
                                                        <option key={i} value={task.alpha3}>{task.countryname}</option>
                                                    )
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select value={this.state.region ? this.state.region : this.state.adr && this.state.adr.region ? this.state.adr.region : this.state.regiondefault} name="region" className="capitalize" onChange={e => this.addressChange(e)}>
                                                <option value="">State / Province / Region</option>
                                                {!this.props.loading && this.props.regions && this.props.regions.map((task, i) => {
                                                    return (
                                                        <option key={i} value={task.regionname}>{task.regionname}</option>
                                                    )
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="capitalize" placeholder="City" value={this.state.locality ? this.state.locality : this.state.adr ? this.state.adr.locality : ''} name="locality" onChange={e => this.addressChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="number" className="capitalize" placeholder="Zip Code" value={this.state.postalCode ? this.state.postalCode : this.state.adr ? this.state.adr.postalCode : ''} name="postalCode" onChange={e => this.addressChange(e)} />
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group align-center mt-20">

                                    <a href="#!" onClick={e => this.updateRelation(e, 'isAddress')} className="btn">Next</a>
                                </div>
                            </div>
                        </div>}
                        {isSocialLinks && <div><div className="form_box">
                            <h3>Step 3: Choose Your Destinations</h3>
                            <div className="destination_block">
                                {destinations && Object.keys(destinations).length > 0 && Object.keys(destinations).map((key) => {
                                    return (
                                        < div className="destination">
                                            <div className="destination_head">
                                                <div className="icon"><i className={"fa fa-" + key}></i>YouTube</div>
                                                <div className="radio">
                                                    <label className="switch">
                                                        <input type="checkbox" name="enable" checked={(destinations[key] || {}).enable || false} onChange={e => this.onChangeSocial(e, key)} id={"is" + key} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {(destinations[key] || {}).enable && <div className="destination_con">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">Stream URL</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" name="streamUrl" value={destinations[key].streamUrl || ''} onChange={e => self.onChangeSocial(e, key)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-0">
                                                    <label className="col-sm-3 col-form-label">Password</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" name="password" value={destinations[key].password || ''} onChange={e => self.onChangeSocial(e, key)} />
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    )
                                })}


                            </div>

                        </div>  <div className="form-group align-center mb-0 mt-30">
                                <a href="#!" onClick={e => this.updateRelation(e, 'isSocialLinks')} className="btn">Submit</a>
                            </div>  </div>}


                    </div>
                </div>

            </div >
        );
    }
}
const mapState = ({ loading, userRelationData, user, partners, userinfo, countries, regions, address, channels }) => ({ loading, userRelationData, user, partners, userinfo, countries, regions, address, channels });
const mapDispatch = { getArtists, customerAddress, updateUserRelation, getUser, addProfiles, getCountries, getRegionsAPI, getAddress, addCustomerInBrainTree, generateActivationCode, getChannels };
export default connect(mapState, mapDispatch)(CreateAnAccount);
