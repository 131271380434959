//export default App;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import axios from "axios";
// import NavLink from './NavLink';
import $ from 'jquery';
import { } from '../../redux/reducers/reducer';
// import windowSize from 'react-window-size';
import braintree from "braintree-web";
var urlParams = new URLSearchParams(window.location.search);
var customer_address = {};
let token = localStorage.getItem('token');
let lambdaUrl = window.site.screeningApiBaseUrl;
let appname = window.app.appName;
let environmentVariable = 'dev';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

class DonationPayment extends Component {
    constructor(props) {
        super(props);

        var plans = JSON.parse(localStorage.getItem("plans"));

        this.state = {
            data: [],
            loader: false,
            planid: (plans && plans.plans && plans.plans[0] && plans.plans[0].planId) || "monthly",
            plans: (plans && plans.plans) || [],
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            postalCode: '',
            checked: false,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            price: localStorage.getItem("assetPrice"),
            assetSubscription: false,
            errMessage: null,
            successmessage: false
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
        // if (urlParams.has('planId') == true) {
        let planId = urlParams.get('planId');
        let assetPrice = localStorage.getItem("assetPrice");
        self.setState({ assetSubscription: true, planid: planId, assetPrice: assetPrice })
        // }

        // document.getElementById(self.state.planid).checked = true;
        document.getElementById("credit").checked = true;


        if (self.state.price === "")
            self.setState({ price: 0 })
        self.braintreeSetup()

    }
    
    braintreeSetup=()=> {
        let self = this;
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('paySubmit');

        braintree.client.create({ authorization: window.site.clientToken }).then(function (client) {
            return braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig
            });
        }).then(function (hostedFields) {
            if (hostedFields.tokenize()) {
                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    hostedFields.tokenize().then(function (payload) {
                        // send payload.nonce to your server

                        const err = self.validate();
                        console.log("errvalid",err)
                        if (!err) {
                            let assettype = localStorage.getItem("a");
                            let eventtype = localStorage.getItem("e");
                            let assetId = localStorage.getItem("assetId") || window.localStorage.getItem("assetId");
                            let channelCode = localStorage.getItem("channelCode");
                            let eventid = localStorage.getItem("eventId");
                            assettype = 'event'
                            if (assettype === "asset") {
                                localStorage.removeItem("channelCode");
                            } else {
                                localStorage.removeItem("a");
                                localStorage.removeItem("assetId") || window.localStorage.removeItem("assetId");
                            }
                            var array = [];
                            var obj;
                            if (assettype === "asset") {
                                obj = { "assetid": assetId }
                            } else if (eventtype === "event") {
                                obj = { "eventid": eventid }
                            } else {
                                obj = { "channelCode": channelCode }
                            }
                            array.push(obj)
                            $("#overlay").removeClass("hidediv");
                            
                            let userAssetType;
                            if (assettype === "asset") {
                                userAssetType = `asset`;
                            } else if (eventtype === "event") {
                                userAssetType = `event`;
                            } else {
                                 userAssetType = `channel`;
                            }

                            let dataparams = `&payment_method_nonce=${payload.nonce}&token=${token}&cardholderName=${self.state.cardholderName}&price=${self.state.price}&itemType=channel&transactionType=donation&userassetType=${userAssetType}`;
                            axios.post(lambdaUrl + '/transaction?appname=' + appname + '&env=' + environmentVariable + '&' + dataparams, array, { type: 'application/json' })
                                .then((response) => {
                                    setTimeout(function () {
                                        $("#overlay").addClass("hidediv");
                                        if (response && response.data && response.data.statusCode === 200) {

                                            if (response.data.result && response.data.result[0] && response.data.result[0].isVerified) {
                                                localStorage.setItem("usub", response.data.result[0].subscriptionplanid || self.state.planid);

                                                //  browserHistory.push(defaultRoute);
                                                this.props.history.push()
                                            } else {
                                                window.app.storage.setItem('donated',self.state.assetPrice);
                                                self.setState({ successmessage: true })
                                                // browserHistory.push("/adddevice");
                                            }

                                        } else {
                                            $("#error").html("Credit Card Validation error")
                                        }

                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })


                        }

                    }).catch(function (err) {
                        console.log("err", err)
                        $("#error").html(err.message)
                    });
                });
            }
        }).catch(function (err) {
            console.log({ err })
            $("#error").html(err.message)
        });

    }
    handleTextChange(e) {
        let self = this;
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            customer_address[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                customer_address
            });
        } else {
            customer_address[e.target.name] = e.target.value;
            self.setState({
                [e.target.name]: e.target.value,
                customer_address
            });
        }
    }
    handleOptionChange(e, price) {
        var self = this;

        self.setState({
            price: price,
            [e.target.name]: e.target.value
        });
        if (price === "")
            self.setState({ price: 0 })
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }
        this.setState({ errors });

        return isError;
    }
    paymentOption(e) {
        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId === "paypal") {
            $("#" + optionId + "-container").removeClass("hidediv");
        } else {
            $("#" + optionId + "card").removeClass("hidediv");
        }
    }

    handleChange(e, item) {
        let self = this;
        let name = e.target.name;
        let value = e.target.value;
        self.setState({ [name]: value });
    }
    successClick() {
        this.setState({ successmessage: false })
        let assetId = localStorage.getItem("assetId") || window.localStorage.getItem("assetId");
        let channelCode = localStorage.getItem("channelCode");

        // localStorage.clear();
        if (assetId) {
            window.location = "/?asset=" + assetId;
        } else if (channelCode) {
            window.location = "/?channel=" + channelCode;
        } else {
            //  browserHistory.goBack();
            this.props.history.goBack()
        }
    }
    render() {
        let self = this;
        let imagePrefix = window.imagePrefix;
        let Title = window.site.config.siteTitle;
        let payment_bg = imagePrefix + "images/landBg_landscape8.jpg";
        if (this.props.windowWidth < 801) {
            payment_bg = imagePrefix + "images/landBg_portrait8.jpg";
        }
        let color = { color: "#f00" }
        const {  assetPrice, successmessage } = this.state;
        return (
            <div>
                <div id="overlay" className="hidediv">
                    <div className="preLoader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="preloader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref="headermenu" className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="logo" src={window.site.logoImage} /></div>
                        <button title="Go back" alt="Go back" onClick={(e) => {this.props.history.goBack(); e.preventDefault();}} className="back"/>
                        {/* <a href="#" title="Logout" alt="Logout" onClick={(e) => this.logout(e)} className="cls"></a> */}
                    </div>
                </div>
                <div className="payment_bg">
                    <img alt="payment" src={payment_bg} />
                </div>
                <div className="payment_blk">
                    <h2>ENTER PAYMENT INFO</h2>
                    <div className="payment_wrp">
                        <div className="pmt_lft">
                            <div className="lock_blk">Secure Server<br />
                                Tell me more</div>
                        </div>
                        <div className="pmt_rgt">
                            <form id="cardForm" method="post">

                             <div className="pmt_blk">
                                    <div className="row">
                                       <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Donation: </label>
                                                <h4>${assetPrice}</h4>
                                            </div>
                                        </div>
                                        {/* // : null} */}
                                    </div>
                                    <br />
                                    <h3>PAYMENT METHOD</h3>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group"><input type="radio" id="credit" name="paymenttype" value="creditcard" onClick={e => this.paymentOption(e)} /><label>CREDIT CARD</label></div>

                                        </div>
                                        <div className="col-md-6 hidediv">
                                            <div className="form-group"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                        </div>
                                    </div>
                                    <div className="cc_det">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARDHOLDER NAME</label>
                                                    <input type="text" className="hosted-field" value={this.state.cardholderName} name="cardholderName" onChange={e => this.handleTextChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cc_det paymentopt" id="creditcard">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARD NUMBER</label>
                                                    <div id="card-number" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group inline-st">
                                                    <label className="pmt_lbl">EXPIRATION</label>
                                                    <div id="expiration-month" className="hosted-field"></div>
                                                    <div id="expiration-year" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>CVV</label>
                                                    <div id="cvv" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>

                                                    <div id="postal-code" className="hosted-field"></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div id="error" style={color} >{self.state.errMessage}</div>

                                    </div>
                                    <div id="paypal-container" className="paymentopt cc_det hidediv"></div>
                                </div>
                                < p className="free_trl">By selecting “Start {Title} Account”, you agree to authorize this charge and to the &nbsp;
                                {/* <NavLink href="#" to='/terms' target="_blank" className="tos">Terms of Service.</NavLink>*/}
                                </p>
                                <div className="form-group">
                                    <input type="button" id="paySubmit" value="Submit" className="submit" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={successmessage}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal u-conf-popup">
                    <div className="active_wrp">
                        <div className="active_block">
                            <p className="wte">Thank you for your support.</p>
                            <div className="form-group">
                                <button type="button" className="submit cncl" onClick={e => self.successClick()}>Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        );
    }
}



export default withRouter(DonationPayment);
