//export default App;
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import axios from "axios";
import NavLink from '../NavLink';
import $ from 'jquery';
import { getCountries,  customerAddress, getAddress, getArtists, msToTime,  } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
import braintree from "braintree-web";
import { Auth0Lock } from 'auth0-lock';
var urlParams = new URLSearchParams(window.location.search);
var partnercode;
var customer_address = {};
var addressVisible = localStorage.getItem("adv");
class SubscriptionPayment extends Component {
    constructor(props) {
        super(props);

        window.token = localStorage.getItem('token');
        if (urlParams.has('partnercode')) {
            partnercode = urlParams.get('partnercode');
        }

        // if (window.siteConfig && window.siteConfig.google && window.siteConfig.google.enable) {
        //     ReactGA.initialize(window.siteConfig.google.key);
        //     ReactGA.pageview(window.location.pathname + window.location.search);
        // }

        // if (window.siteConfig && window.siteConfig.segment && window.siteConfig.segment.enable) {
        //     var options = {
        //         pageName: this.constructor.displayName,
        //         "appName": appname
        //     }
        //     analytics.page(this.constructor.displayName, options);
        // }

        var isLoggedIn = false;
        
        if (!window.token) {
           //window.location = '/';
        } else {
            isLoggedIn = true;
        }
        
        var plans = window.siteConfig && window.siteConfig.subscription && window.siteConfig.subscription.subscriptionPlans;
        console.log('plans',plans);
        var adr = JSON.parse(localStorage.getItem("adr"));
        var planpartner = localStorage.getItem("partnerid");
        let firstName = localStorage.getItem("firstname")
        let lastName = localStorage.getItem("lastname")
        let email = localStorage.getItem("email")
        let phone = localStorage.getItem("phone");
        this.state = {
            data: [],
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnercode,
            planid: plans && plans.plans && plans.plans[0] && plans.plans[0].planId,
            planpartner: planpartner,
            plans: (plans && plans.plans) || [],
            error: '',
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            postalCode: '',
            checked: false,
            adr: adr,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: phone,
            modifiedavatar: window.site.config.imagesAssetResourcesPrefix + 'images/profiles/default/avatar1.jpg' + window.site.config.imageSuffix,
            addressVisible: addressVisible,
            cardholderName: firstName + ' ' + lastName,
            price: (plans && plans.plans && plans.plans[0] && plans.plans[0].price) || 0,
            assetSubscription: false,
            creditCard: true,
            paypal: false,
            googlePay: false,
            applePay: false,
            errMessage: null,
            selectedPlan:'',
            artist_id:'',
            eventInfo: this.props.location.eventInfo,
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.headermenu= React.createRef();
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
        self.loadSiteConfig();
        console.log('this.props.location.eventInfo',this.props.location.eventInfo);
        // let payload;
        // if (assetId) {
        //     this.props.assetDetails(payload);
        // } else {
        //     self.props.channelAssets(channelCode, true)
        // }
        
        
        if (this.props.match.params.planId) {
            let planId = this.props.match.params.planId;
           
                        var selectedPlan = self.state.plans.filter(function( obj ) {
                            return obj.planId === planId;
                        });
            let assetPrice = localStorage.getItem("assetPrice");
            self.setState({ assetSubscription: true, planid: planId, assetPrice: assetPrice, selectedPlan:selectedPlan[0] })
        }

        if (window.siteConfig && window.siteConfig.segment && window.siteConfig.segment.enable) {
            let options = {
                "pageName": this.constructor.name,
                "appName": window.app.appName,
                "path": window.location.pathname,
                "search": window.location.search,
                "referrer": window.location.href,
                "url": window.location.href,
                "UserEmail": localStorage.getItem("email"),
                "userid": localStorage.getItem("userid"),
            }
            window.analytics.page(options);
        }

        document.addEventListener('scroll', () => {
            var isTop;
            if (window.scrollY < 50) {
                isTop = true;
            } else {
                isTop = false;
            }
            if (isTop !== this.state.isTop) {
                if (this.headermenu) {
                    this.onScroll(isTop);
                }
            }
        });

        document.getElementById("creditcard").checked = true;
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.Getpartners !== self.props.Getpartners) {
            self.setState({
                artistData: self.props.Getpartners
            })
        }
        
        
    }
    loadSiteConfig() {
        let self = this;
        let configinterval = setInterval(function () {
            if (window.site) {
                self.setState({ logoImage: window.site.logoImage, preLoader: window.site.preLoader });
                // Braintree
                self.braintreeSetup()
                clearInterval(configinterval);
            }

        }, 1000);
    }
    braintreeSetup() {
        let self = this;
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('paySubmit');
        braintree.client.create({ authorization: window.site.clientToken }).then(function (client) {
            return braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig
            });
        }).then(function (hostedFields) {
            if (hostedFields.tokenize()) {
                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();


                    hostedFields.tokenize().then(function (payload) {
                        console.log('here payload', payload);
                        // send payload.nonce to your server

                        // const err = self.validate();
                        // console.log('err', err)
                        let subPrice = localStorage.getItem("assetPrice");
                        // console.log('subscriptionPrice', subscriptionPrice)
                        
                        
                        let sku = '' ;
                        let envoisku = '' ;
                        if (self.state.selectedPlan && self.state.selectedPlan.quickbooksId) {
                            sku = self.state.selectedPlan.quickbooksId;
                        }
                        if (self.state.selectedPlan && self.state.selectedPlan.sku) {
                            envoisku =  self.state.selectedPlan.sku;
                        }
                        var dateend = new Date();
                        
                        var dataparams = "planId=" + self.state.planid + "&subscription_end=" + dateend + "&payment_method_nonce=" + payload.nonce + "&token=" + window.token + '&streetAddress=' + self.state.streetAddress + '&locality=' + self.state.locality + '&extendedAddress=' + self.state.extendedAddress + '&region=' + self.state.region + '&cardholderName=' + self.state.cardholderName + '&price=' + subPrice + '&artist_id=' + self.state.artist_id + '&type=' + self.state.planid;
                        console.log('dataparams raj',dataparams);
                        if (sku) {
                            dataparams = dataparams + "&sku=" + sku;
                        }
                        if(envoisku){
                            dataparams = dataparams + "&envoisku=" + envoisku;
                        }
                        let emailBody = [{
                            "assetid": self.state.planid,
                            "single_price": subPrice,
                            "userassetType": "product",
                            "assetname": '',
                            "sku": sku,
                            "envoisku": envoisku,
                        }]
console.log('dataparams emailBody',dataparams);
                        
                            axios.post(window.site.screeningApiBaseUrl + '/transaction?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&' + dataparams, emailBody)
                                .then((response) => {
                                    setTimeout(function () {
                                        $("#overlay").addClass("hidediv");
                                        if (response && response.data && response.data.statusCode === 200) {

                                            if (response.data.result && response.data.result[0] && response.data.result[0].isVerified) {
                                                localStorage.setItem("usub", response.data.result[0].subscriptionplanid || self.state.planid);

                                                self.props.history.push("/home");
                                            } else {
                                                //self.props.history.push("/home");
                                                self.props.history.push(`/meeting?room=${self.state.eventInfo.title}`);
                                            }

                                        } else {
                                            $("#error").html("Credit Card Validation error")
                                        }

                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })

                        

                    }).catch(function (err) {
                        $("#error").html(err.message)
                    });
                });
            }
        });

    }

    addressChange(e) {
        const name = e.target.name;
        var value = e.target.value;
        if (name === "countryCodeAlpha2") {
           // self.props.getregions(value)
        }
        customer_address[name] = value;
        this.setState({ [name]: value, customer_address });
    }
    handleTextChange(e) {
        var self = this;
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            customer_address[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                customer_address
            });
        } else {
            customer_address[e.target.name] = e.target.value;
            self.setState({
                [e.target.name]: e.target.value,
                customer_address
            });
        }
    }
    handleCheckboxChange() {
        if (this.state.adr && this.state.adr.postalCode) {
            this.setState({ checked: !this.state.checked, postalCode: this.state.adr.postalCode });
        } else {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleOptionChange(e, price, plan) {
        var self = this;

        self.setState({
            price: price,
            [e.target.name]: e.target.value
        });
        if (price === "")
            self.setState({ price: 0 })

        if (plan && plan.quickbooksId) {
            self.setState({ sku: plan.quickbooksId })
        }
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }
        this.setState({ errors });
        return isError;
    }
    paymentOption(e) {
        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId === "paypal") {
            $("#" + optionId + "-container").removeClass("hidediv");
        } else {
            $("#" + optionId + "card").removeClass("hidediv");
        }
    }
    logout() {
        if (window.signinLock === undefined) {
            window.signinLock = new Auth0Lock(window.clientId, window.domain, {
                auth: {
                    responseType: 'window.token id_token',
                }
            });
            window.signinLock.logout({
                returnTo: '/'
            });
        }
        var myAppName = localStorage.getItem('appName');
        localStorage.clear();
        localStorage.setItem('appName',myAppName);
        window.location = '/';
    }

    handleChange(e, item) {
        let self = this;
        let name = e.target.name;
        let value = e.target.value;
        self.setState({ [name]: value });
    }
    render() {
        let self = this;
        let payment_bg = window.site.config.imagePrefix + "images/landBg_landscape8.jpg";
        if (this.props.windowWidth < 801) {
            payment_bg = window.site.config.imagePrefix + "images/landBg_portrait8.jpg";
        }
        let color = { color: "#f00" }
        // const { assetSubscription, planid, assetPrice } = this.state;
        let contentType = localStorage.getItem("contentType");
        return (
            <div>
                <div id="overlay" className="hidediv">
                    <div className="preLoader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="loader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={this.headermenu} className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="logo" src={window.site.logoImage} /></div>
                        <button style={{"right": "10px"}} title="Go back" alt="Go back" onClick={ev => { ev.preventDefault(); browserHistory.goBack();}} className="back"/>
                    </div>
                </div>
                <div className="payment_bg">
                    <img alt="paymentBG" src={payment_bg} />
                </div>
                <div className="payment_blk">
                    <h2>ENTER PAYMENT INFO</h2>
                    <div className="payment_wrp">
                        <div className="pmt_lft">
                            <h3><span className="bold">STEP 3</span> OF 3</h3>
                            <div className="lock_blk">Secure Server<br />
                                Tell me more</div>
                        </div>
                        <div className="pmt_rgt">
                            <form id="cardForm" method="post">

                                <div className="pmt_blk">
                                    <p className="ps3">ACCOUNT CREATED FOR: {localStorage.getItem("email")}</p>
                                </div>

                                <div className="pmt_blk">
                                    {window.siteConfig && window.siteConfig.artistEnable ? <h3 className="ps3">SELECT ARTIST :
                                        {self.state.artistData && self.state.artistData.length > 0 ? <span>
                                            <select name="artist_id" onChange={e => this.handleChange(e)} value={self.state.artist_id} className="select_dancer">
                                                <option value="">Select</option>
                                                {self.state.artistData && self.state.artistData.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.artist_id}>{item && item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </span> : null}

                                    </h3> : null}
                                    <h3>YOUR SUBSCRIPTION</h3>
                                    <div className="row">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="radio" name="planid" value={contentType} checked={true} />
                                                    <label>{contentType && contentType.toUpperCase()}</label>

                                                </div>
                                            </div>
                                            
                                        </div>

                                        {/* {
                                            assetSubscription == false && this.state.plans && this.state.plans.length > 0 && this.state.plans.map((plan, j) => {
                                                var planid = plan.planId;
                                                var mon = "mo";
                                                if (plan.planId == "yearly") {
                                                    mon = "yr"
                                                }
                                                return (<div className="col-md-6" key={j}>
                                                    <div className="form-group">
                                                    <input type="radio" name="planid" value={contentType} checked={true} />
                                                    <label>{contentType && contentType.toUpperCase()}</label></div>
                                                </div>)
                                            })
                                        }
                                        {assetSubscription == true ?
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="radio" name="planid" value={planid} checked={true} />
                                                    <label>{self.state.planid.toUpperCase()}</label>
                                                    <h4>${assetPrice}/<span className="h4s">mo</span></h4>
                                                </div>
                                            </div>
                                            : null} */}
                                    </div>
                                    <br />
                                    <h3>PAYMENT METHOD</h3>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group"><input type="radio" id="credit" name="paymenttype" value="creditcard" onClick={e => this.paymentOption(e)} /><label>CREDIT CARD</label></div>

                                        </div>
                                        <div className="col-md-6 hidediv">
                                            <div className="form-group"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                        </div>
                                    </div>
                                    <div className="cc_det">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARDHOLDER NAME</label>
                                                    <input type="text" className="hosted-field" value={this.state.cardholderName} name="cardholderName" onChange={e => this.handleTextChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cc_det paymentopt" id="creditcard">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARD NUMBER</label>
                                                    <div id="card-number" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group inline-st">
                                                    <label className="pmt_lbl">EXPIRATION</label>
                                                    <div id="expiration-month" className="hosted-field"></div>
                                                    <div id="expiration-year" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>CVV</label>
                                                    <div id="cvv" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>

                                                    <div id="postal-code" className="hosted-field"></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div id="error" style={color} >{self.state.errMessage}</div>

                                    </div>
                                    <div id="paypal-container" className="paymentopt cc_det hidediv"></div>
                                </div>
                                {
                                    window.settings && window.settings.addressVisible ?
                                        <div className="cc_det ">
                                            <h3>BILLING ADDRESS</h3>

                                            {self.state.addressVisible === "true" ?
                                                <div>
                                                    <div className="bill_check">
                                                        <input type="checkbox" onClick={this.handleCheckboxChange} name="billcheck" /><label>Billing address is same as shipping address</label>

                                                    </div>
                                                    <br />
                                                </div>
                                                : null}

                                            <div className="row col_pad0">

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CARDHOLDER ADDRESS</label>
                                                        <input type="text" value={this.state.streetAddress ? this.state.streetAddress : this.state.checked && this.state.adr ? this.state.adr.streetAddress : ''} placeholder="Street address, P.O. box, Company name, C/o" name="streetAddress" onChange={e => this.addressChange(e)} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>ADDRESS 2</label>
                                                        <input type="text" value={this.state.extendedAddress ? this.state.extendedAddress : this.state.checked && this.state.adr ? this.state.adr.extendedAddress : ''} placeholder="Apartment, suite, unit, building, floor etc" name="extendedAddress" onChange={e => this.addressChange(e)} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>COUNTRY</label>
                                                        <select value={this.state.countryCodeAlpha2 ? this.state.countryCodeAlpha2 : this.state.checked && this.state.adr ? this.state.adr.countryCodeAlpha2 : this.state.countrydefault} name="countryCodeAlpha2" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                            <option value="">Select Country</option>
                                                            {this.props.countries && this.props.countries.map((task, i) => {
                                                                return (
                                                                    <option key={i} value={task.alpha2}>{task.countryname}</option>
                                                                )
                                                            }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>STATE / PROVINCE / REGION</label>
                                                        <select value={this.state.region ? this.state.region : this.state.checked && this.state.adr ? this.state.adr.region : this.state.regiondefault} name="region" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                            <option value="">Select State / Province / Region</option>
                                                            {!this.props.loading && this.props.regions && this.props.regions.map((task, i) => {
                                                                return (
                                                                    <option key={i} value={task.regionname}>{task.regionname}</option>
                                                                )
                                                            }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>CITY</label>
                                                        <input type="text" className="capitalize" value={this.state.locality ? this.state.locality : this.state.checked && this.state.adr ? this.state.adr.locality : ''} name="locality" onChange={e => this.addressChange(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>ZIP / POSTAL CODE</label>

                                                        <input type="number" id="postalCode" value={this.state.postalCode ? this.state.postalCode : this.state.checked && this.state.adr ? this.state.adr.postalCode : ''} onChange={e => this.handleTextChange(e)} name="postalCode" />
                                                        <p className="errorhelp2" style={color}>{this.state.errors.postalCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr /></div>
                                        : null
                                }

                                < p className="free_trl">By selecting “Start {window.site.config.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                <NavLink href="#" to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>
                                <div className="form-group">
                                    <input type="button" id="paySubmit" value="Submit" className="submit" />

                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>

        );
    }
}


const mapState = ({ user, address, countries, regions, Getpartners, }) => ({ user, address, countries, regions, Getpartners, });
const mapDispatch = { getCountries, customerAddress, getAddress, getArtists, msToTime };
export default windowSize(connect(mapState, mapDispatch)(SubscriptionPayment));
