import React, { Component } from 'react';
import { connect } from 'react-redux';
// import OktaSignIn from '@okta/okta-signin-widget';
import $ from 'jquery';
import { Auth0Lock,  Auth0LockPasswordless } from 'auth0-lock';

class SignUp extends Component {
    constructor(props) {
        super(props);
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        let siteAuthenticationConfig = siteConfig.authentication || {};

        // Authentication
        let authenticationKey = siteAuthenticationConfig.key;
        let authenticationSettings = (authenticationKey ? siteAuthenticationConfig[authenticationKey] : null) || {};

        if (siteAuthenticationConfig.key === "auth0") {
            let defaultOptions = {
                container: 'logincontainer',
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                },
                theme: {
                    logo: siteSettings.auth0Logo
                }
            };
            defaultOptions["container"] = 'signupcontainer';

            let klass = (siteSettings.enablePasswordLess) ? Auth0LockPasswordless : Auth0Lock;
            let clientId = authenticationSettings.clientId || authenticationSettings.authClientId
            let clientDomain = authenticationSettings.clientDomain || authenticationSettings.authClientDomain
            siteSettings.signinLock = siteSettings.signupLock = new klass(clientId, clientDomain, defaultOptions);
        }
        // else if (oktaLogin && oktadomain && oktaclientId) {

        //     oktaWidget = new OktaSignIn({
        //         logo: logoImage,
        //         el: '#osw-container',
        //         baseUrl: oktadomain, // https://dev-852395.oktapreview.com  https://sanchaninfo.okta.com
        //         clientId: oktaclientId,
        //         redirectUri: window.location.protocol + "//" + window.location.host + '/',
        //         authParams: {
        //             issuer: oktadomain,
        //             responseType: ['id_token', 'token']
        //         },
        //         scope: 'openid profile email'
        //     });
        // }
    }

    componentDidUpdate() {
        if (this.props.clickAccess) {
            this.signUpLock()
        }
    }
    signUpLock(e) {
        e && e.preventDefault();
        let appStorage = window.app.storage || {};
        let siteSettings = window.site || {};
        let siteConfig = siteSettings.config || {};
        let siteAuthenticationConfig = siteConfig.authentication || {};

        // Authentication
        let authenticationKey = siteAuthenticationConfig.key;
        let authenticationSettings = (authenticationKey ? siteAuthenticationConfig[authenticationKey] : null) || {};

        appStorage.setItem("showDiv", this.props.hiddenDiv)
        appStorage.setItem("removeHidden", this.props.removeHidden)

        if (authenticationKey === "auth0") {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv")

            let options = {
                closable: false,
                languageDictionary: {
                    emailInputPlaceholder: "Enter Email",
                    passwordInputPlaceholder: "Enter Password",
                    signupTitle: 'Create your account with',
                    signUpSubmitLabel: 'Sign Up'
                },
                allowLogin: false
            };
            let auth0SignUpLockArgs = [ options ];

            if (window.enablePasswordLess) {
                auth0SignUpLockArgs.unshift({ passwordlessMethod: 'link' });
            }
            siteSettings.signupLock.show([ ...auth0SignUpLockArgs ]);
        } else if (authenticationKey === "cognito") {
            let cognitoTokenType = authenticationSettings.tokenType || 'token'
            window.location = authenticationSettings.signupCognitoUrl + "?client_id=" + authenticationSettings.clientId + "&response_type=" + cognitoTokenType + "&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin
        } else if (window.oktaLogin) {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            window.oktaWidget.show();
        } else if (authenticationKey === "novi") {
            let novibaseURL = authenticationSettings.baseUrl
            let noviClientId = authenticationSettings.clientId

            window.location = novibaseURL + "/oauth/authorize?client_id=" + noviClientId + "&scope=openid&response_type=code%20token%20id_token&redirect_uri=" + window.location.origin
            
        } else if (authenticationKey === "oauth") {
            console.log('authenticationSettings',authenticationSettings);
            let oauthbaseURL = authenticationSettings.baseUrl
            let oauthClientId = authenticationSettings.clientId
            
            window.location = oauthbaseURL + "/oauth/index/authorize?client_id=" + oauthClientId + "&state=openid,email&response_type=token&redirect_uri=" + window.location.origin
            
        }

    }
    render() {
        const { buttonHide, cssClass, labelText } = this.props

        let siteSettings = window.site || {};
        let siteConfig = siteSettings.config || {};
        let siteAuthenticationConfig = siteConfig.authentication || {};

        // Authentication
        let authenticationKey = siteAuthenticationConfig.key;

        return (
            !buttonHide ? (authenticationKey === "novi" || authenticationKey === "okta") ? null : <a href="#javascript" style={{ "cursor": "pointer" }} onClick={e => this.signUpLock(e)} className={cssClass}>{labelText}</a>
                : null
        )
    }
}


export default connect()(SignUp);
